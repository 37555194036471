// extracted by mini-css-extract-plugin
export var bottomGridController = "grid-edit-controls-module--bottom-grid-controller--9lScw";
export var colAffectedQuantity = "grid-edit-controls-module--col-affected-quantity--+o2rM";
export var columnForm = "grid-edit-controls-module--column-form--khyWj";
export var controlButton = "grid-edit-controls-module--control-button--U-zhg";
export var controlButtonBottom = "grid-edit-controls-module--control-button--bottom--OVz5N";
export var controlButtonSide = "grid-edit-controls-module--control-button--side--Yvt5j";
export var insertFirst__col = "grid-edit-controls-module--insert-first__col--fgAlF";
export var insertFirst__row = "grid-edit-controls-module--insert-first__row--ZY9aQ";
export var insertLast__col = "grid-edit-controls-module--insert-last__col--BM0xV";
export var insertLast__row = "grid-edit-controls-module--insert-last__row--NogbY";
export var rowAffectedQuantity = "grid-edit-controls-module--row-affected-quantity--5Ysow";
export var rowForm = "grid-edit-controls-module--row-form--whUdn";
export var sideGridController = "grid-edit-controls-module--side-grid-controller--O8ZaN";